//
// File: note-render.js
// Auth: Martin Burolla
// Date: 2/28/2022
// Desc: Draws a note to the context.
//

import { isMacOs, isIOS, isFirefox } from "react-device-detect";

//
// Public
//

export const NOTE_RADIUS = 15;

//
// Private
//

// Fonts
const NOTE_FONT = "17px Arial";

// Colors
const NOTE_TEXT_COLOR = "white";
const NOTE_OUTLINE_COLOR = "#003300";

// Magic numbers
const NOTE_TEXT_Y_NUDGE = 4;
const NOTE_TEXT_X_NUDGE = 6;
const IOS_NOTE_TEXT_X_NUDGE = 5;
const NOTE_TEXT_X_FLAT_SHARP_NUDGE = 10;

export const drawHitNote = (ctx, centerX, centerY) => {
    ctx.beginPath();
    ctx.arc(centerX, centerY, NOTE_RADIUS + 1, 0, 2 * Math.PI, false);
    ctx.lineWidth = 4;
    ctx.strokeStyle = "white";
    ctx.stroke();
}

export const drawNote = (ctx, x, y, note, color) => {
    if (note === "-") {
        return;
    }

    // Draw circle.
    ctx.beginPath();
    ctx.arc(x, y, NOTE_RADIUS, 0, 2 * Math.PI, false);
    ctx.fillStyle = color;
    ctx.fill();
    ctx.lineWidth = 1;
    ctx.strokeStyle = NOTE_OUTLINE_COLOR;
    ctx.stroke();

    // Draw text.
    ctx.font = NOTE_FONT;
    ctx.fillStyle = NOTE_TEXT_COLOR;
    if (note.length === 2) { // Nudge the sharps or flats over a bit.
        let flatSharpNudge = NOTE_TEXT_X_FLAT_SHARP_NUDGE;
        // Tweak the nudging for certain devices & browsers.
        if ((isMacOs || isIOS) && note[0] === "♭") {
            flatSharpNudge += IOS_NOTE_TEXT_X_NUDGE; 
        }
        if (isFirefox && note[0] === "♭") {
            flatSharpNudge -= IOS_NOTE_TEXT_X_NUDGE; 
        }
        ctx.fillText(note, x - flatSharpNudge, y + NOTE_TEXT_Y_NUDGE); 
    } else {
        ctx.fillText(note, x - NOTE_TEXT_X_NUDGE, y + NOTE_TEXT_Y_NUDGE);
    }
}
