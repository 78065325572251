//
// File: guitar-scale-engine.mjs
// Auth: Martin Burolla
// Date: 2/12/2022
// Desc: Creates the guitar strings with notes for the arguments passed into getNotes().
//       The sharp and flat symbols are not pound (#) and lower case B (b), they are
//       special characters: ♯ ♭.
//

//
// Public
//

export const tunnings = {
    // First letter is the thickest string.
    4: ["EADG", "DADG"],
    5: ["BEADG", "EADGB"],
    6: ["EADGBE", "DADGBE", "DADGAD", "CADGBE", "DGDGBD", "CGCGCE"],
    7: ["BEADGBE", "AEADGBE", "GDGCFAD", "GCGCGCE", "GCGCFAD", "EBEADGB"],
    8: ["F♯BEADGBE", "EADGCFAD", "ADGCFADG", "EBEADGBE"],
    9: ["C♯F♯BEADGBE"],
    10: ["F♯G♯A♯CEADGBE"]
}
export const neckView = ['Half', 'Full'];
export const stringOptions = [4, 5, 6, 7, 8, 9, 10];
export const keys = ["A", "A♯", "B", "C", "C♯", "D", "D♯", "E", "F", "F♯", "G", "G♯"];
export const noteOptions = [{ name: "Letters", value: "letters" }, { name: "Numbers", value: "numbers"}];
export const scales = [
    { name: "Major",            formula: "1,2,3,4,5,6,7"      }, 
    { name: "Minor",            formula: "1,2,♭3,4,5,♭6,♭7"   },
    { name: "Ionian",           formula: "1,2,3,4,5,6,7"      },
    { name: "Dorian",           formula: "1,2,♭3,4,5,6,♭7"    },
    { name: "Phrygian",         formula: "1,♭2,♭3,4,5,♭6,♭7"  },
    { name: "Lydian",           formula: "1,2,3,4♯,5,6,7"     },
    { name: "Mixolydian",       formula: "1,2,3,4,5,6,♭7"     },
    { name: "Aeolian",          formula: "1,2,♭3,4,5,♭6,♭7"   },
    { name: "Locrian",          formula: "1,♭2,♭3,4,♭5,♭6,♭7" },
    { name: "Blues",            formula: "1,♭3,4,♭5,5,♭7"     },
    { name: "Minor Pentatonic", formula: "1,♭3,4,5,♭7"        },
    { name: "Major Pentatonic", formula: "1,2,3,5,6"          },
    { name: "80s Shredder",     formula: "1,2,♭3,4,♭5,5,6,♭7" },
];

/**
 * Returns a 2D array that represents all the notes to display on a guitar neck.
 * 
 * @param {*} tunning ("EADGBE")
 * @param {*} numFrets (14)
 * @param {*} key ("C")
 * @param {*} formula  ("1,2,3,4,5,6,7")
 * @returns array[][]
 */
 export const getNotes = (tunning, neckWidth, key, formula, noteOptions) => {
    let numFrets = 14; // Don't forget the note behind the nut.
    if (neckWidth > 1024) {
        numFrets = 25; // Don't forget the note behind the nut.
    }

    let retval = null;
    const guitarNotes = createNotesForTunning(tunning, numFrets); 
    const notesToDisplay = getNotesForKeyAndScale(key, formula); 
    retval = hideNotes(guitarNotes, notesToDisplay);          
    if (noteOptions === "numbers") {
        retval = convertNoteLettersToNumbers(retval, notesToDisplay, formula);
    }
    return retval;
}

//
// Private
//

const notes = ["A","A♯","B","C","C♯","D","D♯","E","F","F♯","G","G♯"];

/**
 * Returns an array of notes starting with the note passed in.
 * 
 * @param {*} note 
 * @param {*} quantity 
 * @returns 
 */
const createNotesForStartNote = (note, quantity) => {
    let retval = [];
    let index = notes.findIndex(n => n === note);
    for (let k = 0; k < quantity; k++) {
        retval.push(notes[index])
        index++;
        if (index === 12) {
            index = 0;
        }
    }
    return retval;
}

/**
 * Returns a 2D Array that represents a guitar neck.
 * 
 * @param {*} tunning 
 * @param {*} quantity 
 * @returns 
 */
const createNotesForTunning = (tunning, quantity) => {
    let retval = [];
    for (let k = 0; k < tunning.length; k++) {
        let note = "";
        if (tunning[k + 1] === "♯") {
            note = tunning.substring(k, k + 2); 
            k++;
        } 
        else {
            note = tunning.substring(k, k + 1);
        }
        retval.push(createNotesForStartNote(note, quantity))
    }
    return retval;
}

/**
 * Returns the notes to render, changing the notes we don't want to display to a dash "-".
 * 
 * @param {*} allGuitarNotes 
 * @param {*} notesToDisplay 
 * @returns 
 */
const hideNotes = (allGuitarNotes, notesToDisplay ) => {
    let retval = [];
    allGuitarNotes.forEach(string => {
        let guitarString = []
        string.forEach(note => {
            let r = notesToDisplay.filter(i => i === note);
            if (r.length > 0) {
                guitarString.push(note);
            } else {
                guitarString.push('-');
            }
        })
        retval.push(guitarString);
    })
    return retval;
}

/**
 * Returns a 7 note array for the built in (non-user) scales.
 * 
 * @param {String} key (e.g. "C")
 * @param {String} formula (e.g. "1,2,3,4,5,6,7")
 * @returns The notes that belong to this key and scale.
 */
 const getNotesForKeyAndScale = (key, formula) => {
    const retval = [];
    const wwhWwwh = { 1: 0, 2: 2, 3: 4, 4: 5, 5: 7, 6: 9, 7: 11 } // whole whole half (whole step link) whole whole half
    const formulaArray = formula.split(',');
    const startIndex = notes.findIndex(i => i === key);

    formulaArray.shift();
    retval.push(key);
 
    formulaArray.forEach(i => {
        let index = 0;
        if (i[0] === "♭") {
            index = (startIndex + wwhWwwh[i[1]]) - 1; 
        }
        else if (i[1] === "♯") {
            index = (startIndex + wwhWwwh[i[0]]) + 1; 
        } 
        else {
            index = startIndex + wwhWwwh[i]; 
        }      

        if (index >= notes.length) { // We reached the end of the array and need to wrap to the beginning.
            index = index - notes.length;
        }
        retval.push(notes[index]);
    });
    return retval;
}

/**
 * Returns all the notes to display using their formula number.
 * 
 * @param {*} notes 
 * @param {*} notesToDisplay 
 * @param {*} formula 
 * @returns 
 */
const convertNoteLettersToNumbers = (notes, notesToDisplay, formula) => {
    let retval = [];

    // Build letter to note mapping.
    let letterNumberDict = {};
    letterNumberDict["-"] = "-";
    let formulaArray = formula.split(',');
    for (let i = 0; i < notesToDisplay.length; i++) {
        letterNumberDict[notesToDisplay[i]] = formulaArray[i];
    }

    // Swap out the letters for formula numbers.
    notes.forEach(string => {
        let guitarString = [];
        string.forEach(note => {
            guitarString.push(letterNumberDict[note]);
        })
        retval.push(guitarString);
    })
    return retval;
} 
