import React from 'react'
import { Link } from 'react-router-dom';
import '../css/Header.scss';

export const Header = (props) => {
  return (
    <div className="header">
        <div className="container-fluid">
            <div className="row">
                <div className="col-4 my-auto">
                    <h2>
                        <Link to='/'>{props.text}</Link>
                    </h2>
                </div> 
                <div className="col-8 my-auto">
                    <nav>
                        <Link to='/technology'>TECH BLOG</Link>&nbsp;&nbsp;/&nbsp;&nbsp;
                        <Link to='/guitar-scales'>GUITAR SCALES</Link>&nbsp;&nbsp;/&nbsp;&nbsp;
                        <Link to='/music'>MUSIC</Link>&nbsp;&nbsp;/&nbsp;&nbsp;
                        <Link to='/accomplishments'>ACCOMPLISHMENTS</Link>&nbsp;&nbsp;/&nbsp;&nbsp;
                        <Link to='/contact'>CONTACT</Link>
                    </nav>
                </div>
            </div>
        </div>
    </div>
  )
}
