const cdnassets = {
    /* Audio */
    audioChance: 'https://marty-burolla-cdn.s3.amazonaws.com/Martin-Burolla-Chance.mp3',

    /* Banners */
    bannerHome: 'https://marty-burolla-cdn.s3.amazonaws.com/ban-frs.jpg',
    bannerMusic: 'https://marty-burolla-cdn.s3.amazonaws.com/ban-keyboard.jpg',
    bannerTech: 'https://marty-burolla-cdn.s3.amazonaws.com/ban-technology.jpg',
    bannerFitness: 'https://marty-burolla-cdn.s3.amazonaws.com/ban-dumbbells.jpg',
    bannerFood: 'https://marty-burolla-cdn.s3.amazonaws.com/ban-kitchen.jpg',
    bannerAccomplishments: 'https://marty-burolla-cdn.s3.amazonaws.com/ban-sunset.jpg',
    bannerBGSABlues: 'https://marty-burolla-cdn.s3.amazonaws.com/best-guitar-scales-a-blues.png',
    bannerBGSTab: 'https://marty-burolla-cdn.s3.amazonaws.com/best-guitar-scales-tab-a-blues.png',


    /* Pictures */
    frsDiagram: 'https://marty-burolla-cdn.s3.amazonaws.com/frs-diagram.jpg',
    frsGypsy: 'https://marty-burolla-cdn.s3.amazonaws.com/frs-gypsy.jpg',
    vpcDiagram: 'https://marty-burolla-cdn.s3.amazonaws.com/vpc-diagram.png',
    foodGarbagePlate: 'https://marty-burolla-cdn.s3.amazonaws.com/fd-garbage-plate.jpg',
    foodClamsCasino: 'https://marty-burolla-cdn.s3.amazonaws.com/fd-clams-casino.jpg',
    guitarPracticePad: 'https://marty-burolla-cdn.s3.amazonaws.com/guitar-practice-pad.png',
    cellar: 'https://marty-burolla-cdn.s3.amazonaws.com/acc-cellar.jpg',
    dragon: 'https://marty-burolla-cdn.s3.amazonaws.com/acc-dragon.jpg',
    sgx: 'https://marty-burolla-cdn.s3.amazonaws.com/acc-sgx.jpg',
    sgxschematic: 'https://marty-burolla-cdn.s3.amazonaws.com/acc-sgx-schematic.jpg',
    mburolla1984: 'https://marty-burolla-cdn.s3.amazonaws.com/mburolla-1984.jpg',
    youtubeComments1: 'https://marty-burolla-cdn.s3.amazonaws.com/youtube-comments.png',
    youtubeComments2: 'https://marty-burolla-cdn.s3.amazonaws.com/youtube-comments-2.png',
    fiveSheetMusic: 'https://marty-burolla-cdn.s3.amazonaws.com/fives.jpg',
    jmj: 'https://marty-burolla-cdn.s3.amazonaws.com/jmj.png',
    mxrPhase45: 'https://marty-burolla-cdn.s3.amazonaws.com/phaser.jpg',
    wukar: 'https://marty-burolla-cdn.s3.amazonaws.com/wukar.jpg',
    awsCert: 'https://marty-burolla-cdn.s3.amazonaws.com/aws-cert.png'
};

export default cdnassets;