//
// File: guitar-scale-render.mjs
// Auth: Martin Burolla
// Date: 2/23/2022
// Desc: Draws a keyboard for the guitar string passed into drawKeyboardNotes().
//

import { drawNote } from "./note-render";

//
//  Public
//

export const drawKeyboardNotes = (ctx, guitarString, key) => {
    // guitarString = ['C', 'C♯', 'D', 'D♯', 'E', 'F', 'F♯', 'G', 'G♯', 'A', 'A♯', 'B', 'C']
    drawWhiteKeys(ctx);
    drawBlackKeys(ctx);
    drawAllNotes(ctx, guitarString, key)
}

//
// Private
//

// Colors
const NOTE_ROOT_COLOR = "orange";
const NOTE_BKGRND_COLOR = "green";

// Magic numbers
const KEY_SPACING = 54;
const KEY_LENGTH = 268;
const KEY_BLACK_WIDTH = 30;
const KEY_WHITE_WIDTH = 52;
const KEY_BLACK_LENGTH = 170;
const NOTES_OCTAVE1_START_XPOS = 27;
const NOTES_OCTAVE2_START_XPOS = 405;
const KEY_WHITE_OCTAVE_START_XPOS = 27;
const KEY_BLACK_OCTAVE1_START_XPOS = 22;
const KEY_BLACK_OCTAVE2_START_XPOS = 400;

//
// Keys
//

const drawWhiteKeys = (ctx) => {
    const width = KEY_WHITE_WIDTH;
    let startX = KEY_WHITE_OCTAVE_START_XPOS;
    for (let i = 0; i < 15; i++) {
        ctx.beginPath();
        ctx.moveTo(startX + (i * width), 1);
        ctx.lineTo(startX + (i * width), KEY_LENGTH);
        ctx.strokeStyle = "white";
        ctx.lineWidth = width;
        ctx.stroke(); 
        startX += 2;
    }
}

const drawBlackKeys = (ctx) => {
    drawBlackKeysOctave(ctx, KEY_BLACK_OCTAVE1_START_XPOS);
    drawBlackKeysOctave(ctx, KEY_BLACK_OCTAVE2_START_XPOS);
}

const drawBlackKeysOctave = (ctx, startXPos) => {
    const width = KEY_BLACK_WIDTH;
    const keySpacing = KEY_SPACING;
    
    for (let i = 0; i <= 1; i++) {
        ctx.beginPath();
        ctx.moveTo(startXPos + (keySpacing * i) + (1 * width), 1);
        ctx.lineTo(startXPos + (keySpacing * i) + (1 * width), KEY_BLACK_LENGTH);
        ctx.strokeStyle = "black";
        ctx.lineWidth = width;
        ctx.stroke(); 
    }
    // Extra space...
    for (let i = 3; i <= 5; i++) {
        ctx.beginPath();
        ctx.moveTo(startXPos + (keySpacing * i) + (1 * width), 1);
        ctx.lineTo(startXPos + (keySpacing * i) + (1 * width), KEY_BLACK_LENGTH);
        ctx.strokeStyle = "black";
        ctx.lineWidth = width;
        ctx.stroke(); 
    }
}

//
// Notes
//

const drawAllNotes = (ctx, guitarString, key) => {
    drawNotesForOctave(NOTES_OCTAVE1_START_XPOS, ctx, guitarString, key); 
    drawNotesForOctave(NOTES_OCTAVE2_START_XPOS, ctx, guitarString, key); 
    drawLastNote(NOTES_OCTAVE2_START_XPOS, ctx, guitarString, key); 
}

const drawNotesForOctave = (x, ctx, guitarString, key) => {
    (key === guitarString[0]  || guitarString[0] === "1")  ? drawNote(ctx, x,       215, guitarString[0],  NOTE_ROOT_COLOR) : drawNote(ctx, x,       215, guitarString[0],  NOTE_BKGRND_COLOR);
    (key === guitarString[1]  || guitarString[1] === "1")  ? drawNote(ctx, x + 25,  120, guitarString[1],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 25,  120, guitarString[1],  NOTE_BKGRND_COLOR);
    (key === guitarString[2]  || guitarString[2] === "1")  ? drawNote(ctx, x + 53,  215, guitarString[2],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 53,  215, guitarString[2],  NOTE_BKGRND_COLOR);
    (key === guitarString[3]  || guitarString[3] === "1")  ? drawNote(ctx, x + 79,  120, guitarString[3],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 79,  120, guitarString[3],  NOTE_BKGRND_COLOR);
    (key === guitarString[4]  || guitarString[4] === "1")  ? drawNote(ctx, x + 106, 215, guitarString[4],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 106, 215, guitarString[4],  NOTE_BKGRND_COLOR);
    (key === guitarString[5]  || guitarString[5] === "1")  ? drawNote(ctx, x + 160, 215, guitarString[5],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 160, 215, guitarString[5],  NOTE_BKGRND_COLOR);
    (key === guitarString[6]  || guitarString[6] === "1")  ? drawNote(ctx, x + 187, 120, guitarString[6],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 187, 120, guitarString[6],  NOTE_BKGRND_COLOR);
    (key === guitarString[7]  || guitarString[7] === "1")  ? drawNote(ctx, x + 215, 215, guitarString[7],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 215, 215, guitarString[7],  NOTE_BKGRND_COLOR);
    (key === guitarString[8]  || guitarString[8] === "1")  ? drawNote(ctx, x + 241, 120, guitarString[8],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 241, 120, guitarString[8],  NOTE_BKGRND_COLOR);
    (key === guitarString[9]  || guitarString[9] === "1")  ? drawNote(ctx, x + 270, 215, guitarString[9],  NOTE_ROOT_COLOR) : drawNote(ctx, x + 270, 215, guitarString[9],  NOTE_BKGRND_COLOR);
    (key === guitarString[10] || guitarString[10] === "1") ? drawNote(ctx, x + 295, 120, guitarString[10], NOTE_ROOT_COLOR) : drawNote(ctx, x + 295, 120, guitarString[10], NOTE_BKGRND_COLOR);
    (key === guitarString[11] || guitarString[11] === "1") ? drawNote(ctx, x + 324, 215, guitarString[11], NOTE_ROOT_COLOR) : drawNote(ctx, x + 324, 215, guitarString[11], NOTE_BKGRND_COLOR);
}

const drawLastNote = (x, ctx, guitarString, key) => {
    (key === guitarString[12] || guitarString[12] === "1") ? drawNote(ctx, x + 375, 215, guitarString[12], NOTE_ROOT_COLOR) : drawNote(ctx, x + 375, 215, guitarString[12], NOTE_BKGRND_COLOR);
}
