import React from 'react'
import '../css/Accomplishment.scss';

export const Accomplishment = (props) => {

  return (
    <div className="accomplishment">
      <div className="year">
        { props.year }
      </div>
      <div className="title">
        { props.title }
      </div>
      <div className='text'>
        { props.children }
      </div>
      <div className="d-flex justify-content-center">
        { props.image && <img className="img-fluid" src={ props.image } alt="" /> }
      </div>
      <div className="d-flex justify-content-center">
        { 
          props.videoUrl && 
          <iframe 
              width="800" 
              height="600" 
              src={ props.videoUrl }
              title="Marty Burolla Animusic" 
              frameBorder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen>
          </iframe>
        }
      </div>
      <br/>
    </div>
  )
}
