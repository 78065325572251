import React from 'react'
import { Accomplishment } from './Accomplishment';
import { logPageView } from '../proxies/gaProxy';
import cdnassets from '../util/cdn-assets.js';
import { Footer } from './Footer';
import '../css/Accomplishments.scss';

export const Accomplishments = () => {
  logPageView('/accomplishments');

  return (
      <div className="accomplishments">
        <div className="banner">
         <img className="image" src={ cdnassets.bannerAccomplishments } alt="Fire Rock Sound"/>
      </div>
      <div className="wrapper">
        <div/>
          <div className="center">
            <div>
              <Accomplishment
                year="2019"
                title="YouTube Channel: Marty AWS"
                image={cdnassets.youtubeComments2}>
                  The production of my <a href="http://www.martyaws.com/">YouTube</a> channel is terrible, and the level of effort that I put into this is minimal at best.  But every once and awhile I help somebody, which is something we all need to do.
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="2018"
                  title="Alexa Skill: Star Guitar">
                    Star Guitar is an Alexa skill that returns the formulas and the notes for popular scales in any key.  It's based on a scale engine that I wrote in Javascript.  The scale engine is currently loaded with popular scales, but can be loaded with any scale with it’s associated formula and name (for example: Minor: 1, 2, b3, 4, 5, b6, b7).  Every month Amazon credits my AWS account with $100. 
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="2012"
                  title="iPad iOS App: Guitar Practice Pad"
                  image={cdnassets.guitarPracticePad}>
                    This was the first iOS app I released to the App Store for the iPad. A book from Big Nerd Ranch helped me create this iPad application that dynamically rendered guitar scales to the screen. Users download backing tracks for the selected scale. Believe it or not, this was one of the first applications of its kind. The app was priced at $1.00 and had 92 downloads on the first day.   
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="2010"
                  title="Open Blues Jam at The Cellar, Virginia Tech"
                  image={cdnassets.cellar}>
                    This was my first time I jumped on on a stage and played completely improvised.  I never met these guys before and I had no idea what I was going to be playing.  The highlight of the night was when we played Texas Flood in G minor.  I nailed the solo and the transition out of the solo was unbelievably perfect.  I will never forget the sound of the unexpected applause at the end of my solo in the middle of the song.
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="2009-2014"
                  title="Startup: Jungle Disk Cloud Backup">
                    I was one of the initial members of a small startup in Atlanta called Jungle Disk.  Jungle Disk was one of the first products that allowed users to backup your files from your computer to Amazon S3.  Our company was purchased by Rackspace shortly after we launched.  Being a part of a successful startup was a huge learning experience and it's something that I still draw from today.
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="2003"
                  title="Animusic Project"
                  videoUrl="https://www.youtube.com/embed/Z0tMP921ub4">
                    While I was at RIT, we discussed the challenges associated with syncing computer animation with sound in my computer graphics III class.  Inspired by Wayne Lytle's Animusic videos and my recent purchase of a black Les Paul Standard, I decided to create a video that was driven by the content of a MIDI file.  I created this tune using a keyboard, and created a program to parse a MIDI file and render a hand at 24 frames per second, interpolating finger movements for each frame. Once all the frames were stitched together, I over dubbed a real guitar sound replacing the keyboard sound.
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="1991"
                  title="Colored Pencils"
                  image={cdnassets.dragon}>
                    I drew this using only colored pencils. I really liked a small picture of a dragon that I saw in a magazine. I wanted to make it bigger so I created this 18 inch picture.
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="1991"
                  title="Applied Research and Technology (ART)"
                  image={cdnassets.sgxschematic}>
                    My first job out of college was working as an electronics technician for ART.  ART was formed when MXR dissolved.  I had the pleasure of working with some of the original members of MXR and heard many wild stories.  While I was at ART, we created the world's first guitar analog preamp that included digital effects in one nice package. I remember solving a difficult problem that we noticed with many boards.  We started to see a lot of boards failing a particular THD test from our Audio Precision test station.  Something on this board was causing the THD test to fail.  What could it be?
                </Accomplishment>
                <hr />
                <Accomplishment
                  year="1984"
                  title="Apple Macintosh"
                  image={cdnassets.mburolla1984}>
                    This is where it all started.  I was one of the first kids in the neighborhood to get an Apple Macintosh computer.
                </Accomplishment>
              </div>
          </div> 
        <div/>
      </div>
      <Footer />
    </div>
  )
}
