import React from 'react'
import '../css/Footer.scss';

export const Footer = () => {
  return (
    <div className="footer">
        <div className="container-fluid">
            <hr />
            <div className="row">
                <div className="col">  
                    <div className="d-flex justify-content-start">
                        Built using React 17.0.2.
                    </div>
                </div>
                <div className="col"> 
                    <div className="d-flex justify-content-end">
                        Martin Burolla
                    </div>
                </div>  
            </div>
        </div>
    </div>
  )
}


