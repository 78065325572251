import '../css/App.scss';
import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './Home';
import { Music } from './Music';
import { Technology } from './Technology';
import { Accomplishments } from './Accomplishments';
import { Header } from './Header';
import { GuitarScales } from './GuitarScales';
import { KeyboardScales } from './KeyboardScales';
import { Playground } from './Playground';
import { Contact } from './Contact';

const pkg = require('../../package.json');

function App() {
  useEffect(() => {
    console.log(`${process.env.REACT_APP_ENV_NAME}: v${pkg.version}`)
  }, []); 

  return (
    <BrowserRouter>
      <Header text="MARTY BUROLLA"/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="music" element={<Music />}/>
        <Route path="technology" element={<Technology />}/>
        <Route path="accomplishments" element={<Accomplishments />}/>
        <Route path="guitar-scales" element={<GuitarScales />}/>
        <Route path="keyboard-scales" element={<KeyboardScales />}/>
        <Route path="playground" element={<Playground />}/>
        <Route path="contact" element={<Contact />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
