import React from 'react'
import { logPageView } from '../proxies/gaProxy';
import { TextOverImage } from './TextOverImage';
import { Footer } from './Footer';
import '../css/Home.scss';
import cdnassets from '../util/cdn-assets.js';

export const Home = () => {
  logPageView('/home');

  return (
    <div className="home">
      <div className="banner">
         <img className="image" src={ cdnassets.bannerHome } alt="Fire Rock Sound"/>
      </div>
      <div className="wrapper">
        <div/>
          <div className="center">
            <div>
              <TextOverImage
                imageUrl = { cdnassets.jmj } 
                text = "I had the pleasure of meeting Jean-Michel Jarre and his producer in France via a Skype video conference. Jean-Michel has sold over 80 million albums and made the Guinness Book of Records when he played in front of 1.5 million people in Houston Texas."
                title = "Jean-Michel Jarre"
                textPlacement = "right"
                textColor = "black"
                backgroundColor = "white"
              />
              <TextOverImage
                imageUrl = { cdnassets.fiveSheetMusic } 
                text = "The song Fives by Guthrie Govan changed my life. I had no idea this level of guitar virtuosity was possible. He signed the sheet music for Fives during a week long guitar clinic that I attended. Many people mispronounce his last name. He told me his last name is pronounced like “oven” with a “g” in the beginning."
                title = "Guthrie Govan"
                textPlacement = "left"
                textColor = "black"
                backgroundColor = "white"
              />
              <TextOverImage
                imageUrl = { cdnassets.mxrPhase45 } 
                text = "I won an MXR phaser pedal when I attended Paul Gilbert’s Great Guitar Escape. Paul autographed and annotated it with his usual sense of humor."
                title = "Paul Gilbert"
                textPlacement = "right"
                textColor = "black"
                backgroundColor = "white"
              />
              <img src={cdnassets.wukar} width="900px" alt="" />
            </div>
          </div> 
        <div/>
      </div>
      <Footer />
    </div>
  )
}
