//
// File: gaProxy.js
// Auth: Martin Burolla
// Date: 2/17/2022
// Desc: The one and only interface into Google Analytics. 
//

import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_CODE);

export const logUserSegmentEvent = (segmentName) => {
    try {
        if (process.env.REACT_APP_ENV_NAME === 'PROD') {
            ReactGA.event({
                category: `User Segment`,
                action: `${segmentName}`,
                value: 1
            });
        }
    }
    catch (err) {
        console.log(err);
    }
}

export const logAddNewTabSnippet = (formula, name) => {
    try {
        if (process.env.REACT_APP_ENV_NAME === 'PROD') {
            ReactGA.event({
                category: `Tab Snippet`,
                action: `Added`,
                value: 1
            });
        }
    }
    catch (err) {
        console.log(err);
    }
}

export const logAddNewUserScaleEvent = (formula, name) => {
    try {
        if (process.env.REACT_APP_ENV_NAME === 'PROD') {
            ReactGA.event({
                category: `User Scale Dictionary`,
                action: `Add::Formula: ${formula} :: Name: ${name}`,
                value: 1
            });
        }
    }
    catch (err) {
        console.log(err);
    }
}

export const logScaleEvent = (type, key, scale, tunning, noteoptions) => {
    try {
        if (process.env.REACT_APP_ENV_NAME === 'PROD') {
            ReactGA.event({
                category: `${type} Scale Selection`,
                action: `${type} Scale: ${key} :: ${scale} :: ${tunning} :: ${noteoptions}`,
                value: 1
            });
        }
    }
    catch (err) {
        console.log(err);
    }
}

export const logPageView = (page) => {
    try {
        if (process.env.REACT_APP_ENV_NAME === 'PROD') {
            ReactGA.send({ hitType: "pageview", page: page });
        }
    }
    catch (err) {
        console.log(err);
    }
}
