import React from 'react'

import '../css/ScaleHeader.scss';

export const ScaleHeader = (props) => {
  return (
    <div className='scaleheader'>
        <div className="d-flex justify-content-center">
            <div>{props.musicKey} {props.scale} </div>      
        </div>
        <div className="d-flex justify-content-center">
            <div className="formula">{props.formula.replaceAll(',', ', ')}</div>
        </div>
    </div>
  )
}
