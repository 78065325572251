//
// File: scale-dictionary.mjs
// Auth: Martin Burolla
// Date: 3/3/2022
// Desc: Contains the "cleanup" logic to update a formula entered by the user.
//

export const makeNote = (noteType, noteNumber) => {
    let retval = "";
    if (noteType === "Natural") {
        retval = String(noteNumber);
    } else if (noteType === "Sharp") {
        retval = noteNumber + "♯";
    } else if (noteType === "Flat") {
        retval = "♭" + noteNumber;
    }
    return retval;
}

export const removeNoteAliases = (formula) => {
    let retval = ""
    for (let i = 1; i <= 6; i++) {
        if (findNoteAliases(formula, i + "♯", "♭" + (i + 1))){
            formula.splice(formula.indexOf("♭" + (i + 1)), 1);
            retval = `Removed: ♭${i+1}`;
        }
    }
    return retval;
}

const findNoteAliases = (formula, firstNote, secondNote) => {
    let fn, sn = null;
    fn = formula.find(i => i === firstNote);
    sn = formula.find(i => i === secondNote);
    return (fn && sn) ? true : false;
}
