import React from 'react'
import { useState } from 'react';
import { scales, tunnings, keys, noteOptions, stringOptions, neckView } from '../util/guitar-scale-engine';

import '../css/ScaleSelector.scss'

const DEFAULT_STRING_NUM = 6;

export const ScaleSelector = (props) => {
    let [numStrings, setNumStrings] = useState(DEFAULT_STRING_NUM);

    const onHandleScaleChange = (scaleName) => {
        const formula = scales.filter(s => s.name === scaleName)[0].formula;
        props.onScaleChange({formula, scaleName});
    }

    const onHandleStringOptionChange = (numStrings) => {
        setNumStrings(numStrings);
        const tunning = tunnings[numStrings][0];
        props.onStringOptionChange(tunning);
    }

    return (
    <div className='guitarscaleselector'>
        <div className="d-flex justify-content-center">
            <div className="key">
                <select className="form-select" onChange={e => props.onKeyChange(e.target.value)}>
                    {keys.map(s => <option key={s} value={s}>{s}</option>)}
                </select>
            </div>
            <div className="scale">
                <select className="form-select" onChange={e => onHandleScaleChange(e.target.value)}>
                    {scales.map(s => <option key={s.name} value={s.name}>{s.name}</option>)}
                </select>
            </div>
            { props.showTunnings && 
                <div className="tunning">
                    <select className="form-select" onChange={e => props.onTunningChange(e.target.value)}>
                        {tunnings[numStrings].map(s => <option key={s} value={s}>{s}</option>)}
                    </select>
                </div> }
            { props.showStringOptions && 
                <div className="stringOption">
                    <select className="form-select" value={numStrings} onChange={e => onHandleStringOptionChange(e.target.value)}>
                        {stringOptions.map(s => <option key={s} value={s}>{s}</option>)}
                    </select>
                </div>
            }
            { props.showLetters && 
                <div className="letterOption">
                    <select className="form-select" onChange={e => props.onNoteOptionChange(e.target.value)}>
                        {noteOptions.map(o => <option key={o.name} value={o.value}>{o.name}</option>)}
                    </select>
                </div> 
            }
            { props.showViewOptions && 
                <div className="view">
                    <select className="form-select" onChange={e => props.onNeckViewChange(e.target.value)}>
                        {neckView.map(v => <option key={v} value={v}>{v}</option>)}
                    </select>
                </div> 
            }
        </div>
    </div>
  )
}
