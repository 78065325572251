import React from 'react'
import { KeyboardScaleViewer } from './KeyboardScaleViewer';
import '../css/KeyboardScales.scss';

export const KeyboardScales = () => {
    return (
        <div className="keyboardscales">
            <div className="banner">
                <KeyboardScaleViewer />
            </div>
        </div>
    );
}
