//
// File: localStorageProxy.js
// Auth: Martin Burolla
// Date: 3/10/2022
// Desc: The only and only interface into the browser local storage.
//

// Local storage keys...
const WINDOW_SHADE_TABS = "WSShowTabs";
const TAB_DICTIONARY = "TabDictionary";
const WINDOW_SHADE_SCALES = "WSShowScales";
const USER_SCALE_DICTIONARY = "UserScaleDictionary";

//
// Public
//

export const initLocalStorage = () => {
    initUserPreferences();
}

// GET

export const getWindowShadeScales = () => {
    return JSON.parse(localStorage.getItem(WINDOW_SHADE_SCALES));
}

export const getWindowShadeTabs = () => {
    return JSON.parse(localStorage.getItem(WINDOW_SHADE_TABS));
}

export const getUserScales = () => {
    return JSON.parse(localStorage.getItem(USER_SCALE_DICTIONARY));
}

export const getTabDictionaryLS = () => {
    return JSON.parse(localStorage.getItem(TAB_DICTIONARY));
}

// SET

export const setWindowShadeScales = (userPrefs) => {
    localStorage.setItem(WINDOW_SHADE_SCALES, JSON.stringify(userPrefs));
}

export const setWindowShadeTabs = (userPrefs) => {
    localStorage.setItem(WINDOW_SHADE_TABS, JSON.stringify(userPrefs));
}

export const setUserScales = (userScales) => {
    localStorage.setItem(USER_SCALE_DICTIONARY, JSON.stringify(userScales));
}

export const setTabDictionaryLS = (tabDictionary) => {
    localStorage.setItem(TAB_DICTIONARY, JSON.stringify(tabDictionary));
}

//
// Private
//

const initUserPreferences = () => {
    if (!localStorage.getItem(WINDOW_SHADE_SCALES)) {
        localStorage.setItem(WINDOW_SHADE_SCALES, JSON.stringify(false));
    } 
    if (!localStorage.getItem(WINDOW_SHADE_TABS)) {
        localStorage.setItem(WINDOW_SHADE_TABS, JSON.stringify(false));
    } 
    if (!localStorage.getItem(USER_SCALE_DICTIONARY)) {
        localStorage.setItem(USER_SCALE_DICTIONARY, JSON.stringify({}));
    } 
    if (!localStorage.getItem(TAB_DICTIONARY)) {
        localStorage.setItem(TAB_DICTIONARY, JSON.stringify({}));
    } 
}
