import React from 'react'
import { MusicPlayer } from './MusicPlayer';

import cdnassets from '../util/cdn-assets.js';
import { logPageView } from '../proxies/gaProxy';
import '../css/Music.scss'; 

export const Music = () => {
    logPageView('/music');

    return (
      <div className="music">
        <div className="banner">
          <img className="image" src={ cdnassets.bannerHome } alt="Marty Burolla - Fire Rock Sound"/>
        </div>
        <br />
        <div className="wrapper">
          <div/>
            <div className="center">
              <div>
                <img src={cdnassets.frsDiagram} width="100%" alt="" />
                <img src={cdnassets.frsGypsy} width="100%" alt="" />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div> 
          <div/>
        </div>
        <br/>
        <footer className="footer">
          <MusicPlayer title="Martin Burolla - Chance" audioUrl={cdnassets.audioChance}/>
        </footer>
      </div>
  )
}
