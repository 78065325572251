import React from 'react'
import { BlogIt } from './BlogIt';
import cdnassets from '../util/cdn-assets.js';
import '../css/GuitarScales.scss';

export const GuitarScales = () => {

    const onClick = () => {
        window.open("https://www.bestguitarscales.com");
    }

    return (
    <div className="guitarscales">  
        <div className="banner">
        <img onClick={() => onClick()} className="image" src={ cdnassets.bannerBGSABlues } alt=""/>
        </div>
        <div className="wrapper">
            <div/>
            <div className="center">
                <div>
                    <BlogIt 
                        title = "Best Guitar Scales" 
                        date = "March 20, 2022"
                    >                   

                        <p>
                        The journey of 1,000 miles starts with one line of code.  In this case it started
                        with one React component called <code>GuitarScaleViewer</code>.  It started off very simple,
                        just a proof-of-concept sort of thing.  Before my eyes, quicker than I ever thought possible, 
                        a new website was born: &nbsp; 
                        <a href="https://www.bestguitarscales.com">https://www.BestGuitarScales.com.</a>
                        </p>

                        <p>
                        Best Guitar Scales (BGS) was created with the idea of being the best guitar scale reference
                        on the Internet.  There are plenty of websites that provide this experience but they are 
                        not ideal.  They have a clunky user experience littered with ads and tiny imperfections.
                        BGS improves the user experience and offers users the ability to create and view their own scales, and
                        to create guitar tab snippets by clicking on the notes from the scale the have selected.  
                        Currently, there's nothing like this on the Internet.
                        </p>
                    
                        <div className="banner">
                            <img onClick={() => onClick()} className="image" src={ cdnassets.bannerBGSTab } alt=""/>
                        </div>
                        <br/>

                        <p>
                        BGS started off as a React application which got converted to a 
                        NextJS application.  This was done to enable search engines to discover the application, leveraging
                        the server-side technology that comes with NextJS.  NextJS is fantastic and I highly recommend
                        it for anybody seriously thinking about putting a web application on the Internet.  
                        </p>
                        
                        <p>
                        BGS runs in AWS from an S3 bucket with a CloudFront (cache) distribution in front of it.  
                        Using CloudFront enables lightning fast response times from around the world and enables 
                        BGS to be secure using Amazon's certificate manager.
                        </p>
                    
                    </BlogIt>
                </div>
            </div> 
            <div/>
        </div>
    </div>
    );
}
