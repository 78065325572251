import React from 'react'
import { ScaleSelector } from './ScaleSelector';

import '../css/Playground.scss';

export const Playground = () => {

    return (
        <div className="playground">
           <div className="section">
                <div className="center">
                    Header
                </div>
            </div>
            <div className="center">
                <canvas 
                    className="neck"
                    width={1024} // 1760 1024
                    height={250}
                />
            </div>
            <div className="section">
                <div className="scaleSelection">
                    <div className="center">
                        <ScaleSelector
                            showLetters
                            showTunnings
                            showViewOptions
                            showStringOptions
                        />
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="scaleDictionary">
                    <div className="center">
                        Scale Dictionary
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="tabDictionary">
                    <div className="center">
                        tab Dictionary
                    </div>
                </div>
            </div>
        </div>
    );
}
