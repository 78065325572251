import React from 'react'
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import '../css/MusicPlayer.scss';

export const MusicPlayer = (props) => {
  return (
    <div className="musicplayer">
        <AudioPlayer className="audioplayer" src={props.audioUrl}/>
    </div>
  )
}
