import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import '../css/Contact.scss';

const USER_ID = "2ajIZEuVGoXakOKQ_";
const SERVICE_ID = "service_q4b192b";
const TEMPLATE_ID= "template_3rursog";

export const Contact = () => {
  let [didSend, setDidSend] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
      .then((result) => {
          setDidSend(true);
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="contact">
        <form  ref={form} onSubmit={sendEmail}>
          <label>Name</label>
          <input className="form-control" type="text" name="user_name" />
          <br />
          <label>Email</label>
          <input className="form-control" type="email" name="user_email" />
          <br />
          <label>Message</label>
          <textarea className="form-control" name="message" />
          <br />
          <input className="btn btn-primary" type="submit" value="Send" />
        </form>
        {
          didSend && 
          <div>
            <br/>
            Your message has been sent!
          </div>
        }
    </div>
  );
};
