import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { removeNoteAliases, makeNote } from '../util/scale-dictionary.mjs';
import { logAddNewUserScaleEvent } from '../proxies/gaProxy';
import { initLocalStorage, getUserScales, setUserScales } from '../proxies/localStorageProxy';
import toast, { Toaster } from 'react-hot-toast';

import '../css/UserScaleDictionary.scss';

export const UserScaleDictionary = (props) => {
    let formulaRef = useRef(null); // <input>
    let newScaleNameRef = useRef(null); // <input>
    let [noteNumber, setNoteNumber] = useState(1); 
    let [newFormula, setNewFormula] = useState(["1"]); 
    let [noteType, setNoteType] = useState("Natural"); 
    let [modArray, setModArray] = useState(["Sharp", "Flat"]); 
    let [newScaleName, setNewScaleName] = useState("");
    let [userScaleDictionary, setUserScaleDictionary] = useState({}); 
    let [disableAddButton, setDisableAddButton] = useState(true);

    useEffect(() => { 
        initLocalStorage();
        setUserScaleDictionary(getUserScales());
        formulaRef.current.value = "1";
    },[]);

    const showToast = (msg) => toast(msg);

    const onAddNote = () => {      
        newFormula.push(makeNote(noteType, noteNumber));
        newFormula = Array.from(new Set(newFormula)); // Remove Dupes
        let errorMessage = removeNoteAliases(newFormula);
        if (errorMessage) {
            showToast(errorMessage);
        }
        setNewFormula(newFormula);
        formulaRef.current.value = newFormula.join(', ')
    }

    const onAddNewScale = () => {
        userScaleDictionary[newFormula.join()] = newScaleName;   
        setUserScales(userScaleDictionary);
        setNewFormula(["1"]);
        setNewScaleName("");
        formulaRef.current.value = "1";
        newScaleNameRef.current.value = "";
        setDisableAddButton(true);
        logAddNewUserScaleEvent(newFormula.join(), newScaleName);
    }
    
    const onDeleteScale = (formula) => {
        delete userScaleDictionary[formula];
        const userScaleDictionaryDeepCopy = {...userScaleDictionary}; // Force the state to change and force a re-render.
        setUserScaleDictionary(userScaleDictionaryDeepCopy);
        setUserScales(userScaleDictionaryDeepCopy);
    }

    const onSetNewScaleName = (scaleName) => {
        setNewScaleName(scaleName);
        (scaleName === "") ? setDisableAddButton(true) : setDisableAddButton(false);
    }

    const onSetNoteNumber = (e) => {
        let noteNumber = parseInt(e);
        if (noteNumber === 1) {
            setModArray(["Sharp", "Flat"]) 
        }
        if (noteNumber >= 2 && noteNumber < 7) {
            setModArray(["Natural", "Sharp", "Flat"])
        }
        if (noteNumber === 7) {
            setModArray(["Natural", "Flat"])
        }
        setNoteNumber(e)
    }

    return (
        <div className="userscaledictionary">
            <Toaster 
                containerStyle={{
                    position: 'relative',
                }}
                toastOptions={{
                    className: '',
                    style: {
                        border: '1px solid #2e77d0',
                        padding: '16px',
                        color: 'white',
                        background: '#2e77d0'
                    }
                }}
            />
            <div>
                <select className="" name="noteNumber" id="noteNumber" onChange={(e) => {onSetNoteNumber(e.target.value)}}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                </select>
                <select className="modifier" name="noteType" id="noteType" onChange={(e) => {setNoteType(e.target.value)}}>
                    {
                        modArray.map(i =>
                            <option key={i} value={i}>{i}</option>
                        )
                    }
                </select>
                <button className="btn btn-secondary" onClick={() => {onAddNote()}}>&gt;&gt;</button>
                <input ref={formulaRef} disabled={true}></input>
                <input placeholder='Scale name' ref={newScaleNameRef} onChange={(e) => {onSetNewScaleName(e.target.value)}}></input>
                <button className="btn btn-success" onClick={() => {onAddNewScale()}} disabled={disableAddButton} >Add</button>
                <br /> <br />
                <table className="scaleTable">
                    <tbody>
                        {
                            userScaleDictionary && Object.keys(userScaleDictionary).map((key, index) => ( 
                                <tr key={index}>
                                    <td id="formula">{key.replaceAll(',', ', ')}</td>
                                    <td id="name">{userScaleDictionary[key]}</td>
                                    <td id="action" onClick={() => {props.onViewScale({formula: key, scaleName: userScaleDictionary[key]})}}><button className="btn btn-secondary">View</button></td> 
                                    <td id="action" onClick={() => {onDeleteScale(key)}}><button className="btn btn-danger">Delete</button></td> 
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
