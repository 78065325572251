import React from 'react'
import '../css/BlogIt.scss';

export const BlogIt = (props) => {
  return (
    <div className="blogit">
      <div className="header">
          {props.title}
      </div>
      <div className="date">
          {props.date}
      </div>
      <div className="body">
          {props.children}
      </div>                    
      <div className="youtube">
        {props.youTubeUrl && <iframe 
            width="700" 
            height="400" 
            src={props.youTubeUrl} 
            title={props.youTubeTitle}  
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
        </iframe>}
      </div>
      <div className="image">
        {props.image && <img src={props.image} alt=""/> }
      </div>
      <div>
        {props.showSeparator && <hr/> }
      </div>
    </div>
  )
}
