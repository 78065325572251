import React from 'react'

import '../css/TextOverImage.scss';

export const TextOverImage = (props) => {
    let imageClassName = "imageRight";
    let textClassName = "textLeft";

    if (props.textPlacement === "right") {
        imageClassName = "imageLeft";
        textClassName = "textRight";
    } 

    return (
        <div className="textImage">
            <img className={imageClassName} src={props.imageUrl} alt=""/>
            <div className={textClassName} style={{ backgroundColor : props.backgroundColor, color : props.textColor }}> 
                <div className="title" style={{ color : props.textColor }}>
                    {props.title}
                    <br />
                </div>
                {props.text}
            </div>
        </div>
    )
}
