import React from 'react'
import { useRef, useEffect, useState } from 'react';
import { drawKeyboardNotes } from '../util/keyboard-scale-render';
import { ScaleHeader } from './ScaleHeader';
import { ScaleSelector } from './ScaleSelector';
import { getNotes } from '../util/guitar-scale-engine'; 
import { logScaleEvent, logUserSegmentEvent } from '../proxies/gaProxy';
import { UserScaleDictionary } from './UserScaleDictionary';

import '../css/KeyboardScaleViewer.scss';

const WIDTH = 810;
const HEIGHT = 270;
const NUM_KEYS = 13;
const TUNNING = "CCCCCC";

export const KeyboardScaleViewer = () => {
    let [key, setKey] = useState("A");
    let [scaleName, setScaleName] = useState("Major");
    let [formula, setFormula] = useState("1,2,3,4,5,6,7");
    let [noteOptions, setNoteOptions] = useState("letters");
    const ctx = useRef(null);
    const canvas = useRef(null);

    useEffect(() => { // GA4
        logUserSegmentEvent("Keyboard");
    },[]);

    useEffect(() => { // GA4
        logScaleEvent('Keyboard', key, scaleName, TUNNING, noteOptions);
    },[key, scaleName, formula, noteOptions]);

    useEffect(() => { // Context
        const canvasElement = canvas.current;
        canvasElement.width = canvasElement.clientWidth;
        canvasElement.height = canvasElement.clientHeight;
        ctx.current = canvasElement.getContext("2d");
    },[key, scaleName, formula, noteOptions]);

    useEffect(() => { // Notes & Render
        const guitarNotes = getNotes(TUNNING, NUM_KEYS, key, formula, noteOptions);
        drawKeyboardNotes(ctx.current, guitarNotes[0], key);
    },[key, scaleName, formula, noteOptions]);

    const onSetScaleAndFormula = (scale) => {
        setFormula(scale.formula);
        setScaleName(scale.scaleName);
    }

    return (
        <div className="keyboardscaleviewer">
            <div className="content">
                <ScaleHeader 
                    musicKey = {key} 
                    scale = {scaleName} 
                    formula = {formula} 
                />
                <div className="keys">
                    <canvas className="canvas" width={WIDTH} height={HEIGHT} ref={canvas}></canvas>
                </div>
                <ScaleSelector
                    showLetters
                    onKeyChange = {k => setKey(k)} 
                    onScaleChange = {s => onSetScaleAndFormula(s)}  
                    onNoteOptionChange = {o => setNoteOptions(o)}             
                />
                <div className="wrapper">
                    <div className="center">
                        <UserScaleDictionary onViewScale={s => onSetScaleAndFormula(s)}/>
                    </div> 
                </div>
            </div>
        </div>
    );
}
